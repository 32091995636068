<template>
  <div class="d-flex flex-column flex-lg-row">
    <b-modal id="modal-delete-danger" ok cancel ok-variant="danger" ok-title="Accept" modal-class="modal-danger" centered
      title="Delete Video" @ok="deleteCustomVideo()">
      <b-card-text> Are you sure you want to delete this Video ? </b-card-text>
    </b-modal>
    <div class="w-lg-50 w-100">
      <b-col cols="12">
        <b-card title="Add Custom Vedio">
          <validation-observer ref="appsForm" #default="{ invalid }">
            <b-form>
              <!--* title Vedio -->
              <b-form-group label-for="Title Video" label="Title Video">
                <validation-provider #default="{ errors }" name="Title Video" rules="required">
                  <b-form-input id="titleVideo" v-model="title" name="titleVideo"
                    :state="errors.length > 0 ? false : null" placeholder="ex : Applead First Video Tutorial" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--* Url Vedio -->
              <b-form-group label-for="Url Video" label="Url Video">
                <validation-provider #default="{ errors }" name="Url Video" rules="required|url">
                  <b-form-input id="urlVideo" v-model="url" name="url Video" :state="errors.length > 0 ? false : null"
                    placeholder="ex : https://www.youtube.com/watch?v=nhBVL41-_Cw" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button variant="primary" block @click="storeUrl" :disabled="invalid">
                {{ btnTitle }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </div>
    <div class="w-lg-50 w-100">
      <b-col cols="12">
        <b-card title="Urls">
          <b-table
            class="w-100"
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
          >
            <template #cell(title)="data">
              <p class="url-vid" :title="data.value">{{ data.value }}</p>
            </template>
            <template #cell(url)="data">
              <p class="url-vid" :title="data.value">{{ data.value }}</p>
            </template>

            <template #cell(actions)="data">
              <div class="d-flex">
                <b-button variant="flat-danger" class="btn-icon rounded-circle" title="delete video"
                  @click="popDeleteVideoPopUp(data.item.id)">
                  <feather-icon icon="TrashIcon" size="20" />
                </b-button>
                <b-button variant="flat-warning" class="btn-icon rounded-circle" title="go to update this video"
                  @click="insertToInputs(data.item.id)">
                  <feather-icon icon="EditIcon" size="20" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@axios";

import { required, regex } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BImg,
  BCardTitle,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BContainer,
  BRow,
  BCol,
  BCard,
  BTable,
  BTr,
  BTd,
  BModal,
} from "bootstrap-vue";
import { getAccessTokenFromCookie } from "@/auth/utils";
import router from "@/router";

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  },
  data() {
    return {
      id: null,
      url: "",
      title: "",
      fields: [
        {
          label: "#",
          key: "id",
        },
        "title",
        "url",
        // "status",
        "Actions",
      ],
      items: [],
      accessToken: null,
      btnTitle: "create",
    };
  },
  mounted() {
    const data = getAccessTokenFromCookie();
    const userType = data[2].role;
    if (userType !== "admin") {
      router.push({ name: "error-404" })
    }
    this.getCustomVideos();
  },
  methods: {
    popDeleteVideoPopUp(id) {
      this.id = id;
      this.$root.$emit("bv::show::modal", "modal-delete-danger");
    },
    insertToInputs(id) {
      this.btnTitle = "update";
      this.id = id;
      const video = this.items.filter((x) => x.id === id);
      this.url = video[0].url;
      this.title = video[0].title;
    },

    deleteCustomVideo() {
      let formData = new FormData();
      formData.append("id", this.id);
      if (this.id) {
        axios
          .post("/delete-custom-video", formData)
          .then(({ data }) => {
            if (data.status === "success") {
              this.showToast("success", "CheckIcon", "Success", data.message);
              this.getCustomVideos();
              this.id = null;
            } else {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Please try again later."
            );
          });
      }
    },
    storeUrl() {
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("title", this.title);
      formData.append("url", this.url);
      if (!this.id) {
        axios
          .post("/store-custom-video", formData)
          .then(({ data }) => {
            if (data.status === "success") {
              this.showToast("success", "CheckIcon", "Success", data.message);
              this.getCustomVideos();
              this.url = null;
              this.id = null;
              this.title = null;
            } else {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Please try again later."
            );
          });
      } else {
        axios
          .post("/update-custom-video", formData)
          .then(({ data }) => {
            if (data.status === "success") {
              this.showToast("success", "CheckIcon", "Success", data.message);
              this.getCustomVideos();
              this.url = null;
              this.id = null;
              this.title = null;
              this.btnTitle = "create";
            } else {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
            }
          })
          .catch((error) => {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Please try again later."
            );
          });
      }
    },
    getCustomVideos() {
      axios
        .post("/get-custom-videos")
        .then(({ data }) => {
          if (data.status === "success") {
            this.items = data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.links {
  color: inherit;
}

.url-vid {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
